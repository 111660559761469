.main-content {
    min-height: 80vh;
    text-align: center;
    padding: 0% 5%;
}

.method-div {
    margin-top: 20px;
}

.response-div {
    border: 5px solid grey;
}

.content-div {
    margin-top: 40px;
    margin-bottom: 40px;
}

pre {
    text-align: left;
    border: 5px solid grey;
}